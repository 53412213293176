;(function($){

    function Modal() {

		var _  = this;
		_.API  = $("#app_url").val() + "/api/";
		_.uri  = null;
		_.page = null;		

		routie("produto/:name", function(name) {
			_.uri  = 'produtos/'+ name;
			_.page = "/produtos";

  		   	_.init();	
		});

		routie("obra/:name", function(name) {
			_.uri  = 'obras/'+ name;
			_.page = "/obras";
			_.init();
		});
    }

    Modal.prototype.init =  function() {

    	var _ =  this;

    	$("#modal").iziModal({		
			bodyOverflow: true,
			closeOnEscape: false,
			overlayClose: false,
			width: '85%',
			overlayColor: 'rgba(0, 0, 0, 0.65)',
			 history: false,
    		autoOpen: true,
			onOpened: function(){
				$('.tumbs-slider').slick({
				  dots: false,
				  infinite: false,
				  speed: 300,
				  slidesToShow: 5,
				  slidesToScroll: 1,
				  arrows: true, 
				  asNavFor: '.full-slider',
				  focusOnSelect: true,
				  responsive: [
				    {
				      breakpoint: 1024,
				      settings: {
				        slidesToShow: 4
				      }
				    },
				    {
				      breakpoint: 600,
				      settings: {
				        slidesToShow: 3,
				      }
				    },
				    {
				      breakpoint: 480,
				      settings: {
				        slidesToShow: 2,
				      }
				    }
				  ]
				});

				$('.full-slider').slick({
				  dots: false,
				  infinite: true,
				  speed: 500,
				  fade: true,
				  cssEase: 'linear',
				  asNavFor: '.tumbs-slider',
				  arrows: false
				});	

				$(".construction").css({opacity: 1});
				_.modal.stopLoading(); 	
			},
		    onOpening: function(modal){	

		    	_.modal = modal;

		        modal.startLoading();

		        $.get( _.API + _.uri, function(data) {
		            $("#modal .iziModal-content").html(data);		   		 		    
		        });
		    },
		    onClosed: function() {
		    	$('.full-slider').slick('unslick');
		    	$('.tumbs-slider').slick('unslick');
		    	window.location = $("#app_url").val() +_.page;
		    }		 
		});
    };
 
   	new Modal();

}(jQuery));
 
 
 
 
