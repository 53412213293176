;(function($){

    function Slides() {
   	 	var _ =  this;

   	 	$('.content-slider .slider').slick({
			dots: false,
			infinite: true,
			speed: 500,
			fade: true,
			cssEase: 'linear',
			autoplay: true,
		    autoplaySpeed: 3500,
		});

		$('.customers .carrossel').slick({
		  dots: false,
		  infinite: false,
		  speed: 300,
		  arrows: false,
		  slidesToShow: 4,
		  slidesToScroll: 1,
		  autoplay: true,
		  autoplaySpeed: 2000,
		  responsive: [
		    {
		      breakpoint: 1024,
		      settings: {
		        slidesToShow: 3,
		        slidesToScroll: 1,
		      }
		    },
		    {
		      breakpoint: 600,
		      settings: {
		        slidesToShow: 2,
		        slidesToScroll: 1
		      }
		    },
		    {
		      breakpoint: 380,
		      settings: {
		        slidesToShow: 1,
		        slidesToScroll: 1
		      }
		    }
		  ]
		});		
    }
    
   	new Slides();

}(jQuery));
 
 
 
 
